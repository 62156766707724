.experience-page {
  padding-top: 64px;
  min-height: calc(100vh - 64px);
  width: 100vw;
  font-family: League Spartan;
}

.skills-list::before{
    color: #BFA181;
    content: "▹ ";
    left: 0;
    position: absolute;
}