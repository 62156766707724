[type="radio"] {
  position: absolute;
  visibility: hidden;
}
.item {
  position: absolute;
  width: 60%;
  height: 75%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}
@media screen and (min-width: 900px){
    .item{
        height: 80%;
    }
}
@media screen and (min-width: 1200px){
    .item{
        height: 95%;
        width: 50%;
    }
}
.item img:hover{
    transition: transform 0,4s ease-in-out;
    transform: translateY(-5px);
}

#item-1:checked ~ .items #project-2,
#item-2:checked ~ .items #project-3,
#item-3:checked ~ .items #project-1{
  transform: translatex(40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .items #project-3,
#item-2:checked ~ .items #project-1,
#item-3:checked ~ .items #project-2 {
  transform: translatex(-40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}
#item-1:checked ~ .items #project-1,
#item-2:checked ~ .items #project-2,
#item-3:checked ~ .items #project-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;

  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}
